<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <list-page
        :columns="columns"
        :headers="headers"
        :popupComponent="popupComponent"
        :basic-data="basicData"
        sort="site_id"
        title="Site List"
        icon="mdi-sitemap"
        addButtonText="Site"
        stateendpoint="site.sites"
        :actions="actions"
    />
  </div>
</template>
<script>
import ListPage from "../components/ListPage";
import SitePopup from "../components/popups/SitePopup";

export default {
  name: 'Sites',
  metaInfo: {
    title: 'webexpressmedia.com',
    titleTemplate: 'Admin Dashboard - Sites | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {ListPage, SitePopup},
  data() {
    return {
      headers: [
        {
          text: 'Site ID',
          value: 'siteId'
        },
        {
          text: 'Site URL',
          value: 'siteUrl'
        },
        /*{
          text: 'DA',
          align: 'left',
          value: 'da'
        },
        {
          text: 'PA',
          align: 'left',
          value: 'pa'
        },
        {
          text: 'DR',
          align: 'left',
          value: 'dr'
        },
        {
          text: 'UR',
          align: 'left',
          value: 'ur'
        },
        {
          text: 'Traffic',
          align: 'left',
          value: 'traffic'
        },
        {
          text: 'Back Links',
          value: 'backLinks'
        },*/
        {
          text: 'Google News',
          value: 'googleNews'
        },
        {
          text: 'Other',
          value: 'casino'
        },
        {
          text: 'Do Follow',
          value: 'follow'
        },
        {
          text: 'Admin Price',
          align: 'right',
          value: 'adminPrice'
        },
        {
          text: 'Price',
          align: 'right',
          value: 'price'
        },
        {
          text: 'Reseller Price',
          align: 'right',
          value: 'resellerPrice'
        },
        {
          text: 'Ahrefs',
          value: 'ahrefs'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Is check broken link?',
          value: 'isCheckBrokenLink',
          align: 'right'
        },
        {
          text: '', value: 'actions', sortable: false
        }
      ],
      columns: [
        {value: 'siteUrl'},
        {value: 'da'},
        {value: 'pa'},
        {value: 'dr'},
        {value: 'ur'},
        {value: 'backLinks'},
        {value: 'googleNews'},
        {value: 'casino'},
        {value: 'follow'},
        {value: 'price', type: 'currency'},
        {value: 'resellerPrice', type: 'currency'},
        {value: 'isActive'},
        {value: 'action'},
      ],
      actions: {
        load: 'loadSites',
        create: 'storeSite',
        update: 'updateSite',
        remove: 'removeSite',
        destroy: 'destroySite'
      },
      basicData: {
        pa: 0,
        da: 0,
        dr: 0,
        ur: 0,
        traffic: 0,
        backLinks: 0,
        adminPrice: 0,
        price: 0,
        resellerPrice: 0,
        linkPrice: 0,
        adultPrice: 0,
        follow: true,
        isActive: true,
        isCheckBrokenLink: true,
        googleNews: true,
        casino: true,
        nicheCategories: []
      }
    }
  },
  computed: {
    popupComponent() {
      return SitePopup;
    }
  },
  mounted() {
    this.$store.dispatch('loadNicheCategoriesByStatus')
  }
}
</script>
